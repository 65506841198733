import React, { useState } from "react"
import base64 from "base-64"

import ToolLayout from "../../components/layout/tool"

export default () => {
  const [errorMsg, setErrorMsg] = useState("")
  const [encodedValue, setEncodedValue] = useState("")
  const [decodedValue, setDecodedValue] = useState("")

  var encode = () => {
    try {
      setErrorMsg("")
      setEncodedValue(base64.encode(decodedValue))
    } catch (err) {
      setErrorMsg(err.message)
    }
  }

  var decode = () => {
    try {
      setErrorMsg("")
      setDecodedValue(base64.decode(encodedValue))
    } catch (err) {
      setErrorMsg(err.message)
    }
  }

  return (
    <ToolLayout title="Base64 Encode/Decode">
      <div className="headline">
        <h1 className="text-primary m-0">Base64 Encode/Decode</h1>
      </div>
      <p className="text-danger">{errorMsg}</p>
      <div className="row">
        <div className="col-md-5">
          <textarea
            className="form-control"
            rows="5"
            onChange={e => {
              setDecodedValue(e.target.value)
            }}
            value={decodedValue}
            placeholder="Put in text to be encoded"
          />
        </div>
        <div className="col-md-2">
          <button className="btn btn-light m-2" onClick={encode}>
            Encode
          </button>
          <button className="btn btn-light m-2" onClick={decode}>
            Decode
          </button>
        </div>
        <div className="col-md-5">
          <textarea
            className="form-control"
            rows="5"
            onChange={e => {
              setEncodedValue(e.target.value)
            }}
            value={encodedValue}
            placeholder="Put in text to be decoded"
          />
        </div>
      </div>
    </ToolLayout>
  )
}
