import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import Navbar from "./navbar"
import Footer from "./footer"

export default ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Tools | Developer Portal | Liquid Group Pte. Ltd.</title>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans"
          rel="stylesheet"
        />
      </Helmet>
      <Navbar />
      <div id="master-container">
        <div id="documentation" className="container">
          <div id="doc-sidebar-parent">
            <div id="doc-sidebar" className="scrollable nounderline">
              <h4>Tools</h4>
              <ul>
                <li>
                  <Link to="/tools/base64">Base64 Encode/Decode</Link>
                </li>
                <li>
                  <Link to="/tools/liquidnet-signature-generator">
                    LiquidNet Signature Generator
                  </Link>
                </li>
                <li>
                  <Link to="/tools/liquidpay-signature-generator">
                    LiquidPay Signature Generator
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div id="doc-content-parent">
            <div id="doc-content">{children}</div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}
